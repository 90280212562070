<template>
  <nav id="mainNavBar" class="flex flex-col h-full border-r bg-card flex-nowrap flex-b border-form">
    <app-logo class="flex-none mx-auto w-12 h-12 my-6" />
    <tx-button
      v-for="moduleObj in enabledModules" :id="`mod${moduleObj.id}`" :key="moduleObj.id"
      v-tooltip="{ text: moduleObj.label, theme: { placement: 'right' } }" :faicon="moduleObj.icon" :active="moduleObj.active" type="nav"
      class="mx-auto mb-4" @click="doGoto(moduleObj.routeName)"
    />
    <div class="flex flex-col flex-wrap justify-end mb-6 space-y-4 grow">
      <tx-button
        v-show="isAdmin" id="modAdmin" v-tooltip="{ text: t('routes.Admin'), theme: { placement: 'right' } }" faicon="fa-light fa-database" type="nav"
        class="mx-auto" @click="doGoto('Admin')"
      />
      <tx-button
        v-show="getSwitchVisible" id="modRefresh" v-tooltip="{ text: t('general.refresh'), theme: { placement: 'right' } }" faicon="fa-light fa-refresh" type="nav"
        class="mx-auto" @click="doRefreshData(undefined)"
      />
      <tx-button
        v-show="getSwitchVisible" id="modExport" v-tooltip="{ text: t('general.export'), theme: { placement: 'right' } }" faicon="fa-light fa-file-export" type="nav"
        class="mx-auto" @click="doExport"
      />
      <tx-button
        v-show="getSwitchVisible" id="modSwitch" v-tooltip="{ text: t('general.switchCatalog'), theme: { placement: 'right' } }"
        faicon="fa-light fa-group-arrows-rotate" type="nav" class="mx-auto"
        @click="switchWarningDialogVisible = true"
      />
      <notifications-dropdown @refresh="doRefreshData" @show-error-information="showErrorInformation" @show-request-details="showRequestDetails" />
      <div class="w-8 h-8 mx-auto">
        <tx-dropdown>
          <template #button>
            <div
              v-show="getInitials !== ''"
              class="flex items-center justify-center w-8 h-8 mx-auto text-sm font-normal align-middle rounded-full border-primary bg-primary text-muted text-on-primary"
            >
              {{ getInitials }}
            </div>
          </template>
          <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class>
            <li
              v-for="(option, action) in userOptions" id="listbox-item-0" :key="option.label" v-tooltip="option.tooltip"
              tabindex="0" role="option" class="relative flex items-center pl-2 pr-3 text-gray-900 cursor-pointer select-none h-9 hover:bg-grey-light focus:outline-none focus:bg-grey-light"
              @click="doUserAction(action, option)"
            >
              <div class="flex items-center justify-center text-base truncate">
                <font-awesome-icon :icon="option.icon" class="w-4 h-4 mr-2 grow" />
                {{ option.label }}
              </div>
            </li>
          </ul>
        </tx-dropdown>
      </div>

      <!-- Export dialog -->
      <export-dialog ref="refExportDialog" />
      <!-- This component is rendering always even without notification error so adding v-if -->
      <notification-error-dialog v-if="showNotificationErrorDialog" ref="refNotificationErrorDialog" />

      <tx-dialog
        v-model="switchWarningDialogVisible" :title="t('general.alert')"
        show-ok-cancel @click="switchWarningDialogVisible = false" @ok="onConfirmSwitchCatalog()"
      >
        <div class="text-xl">
          {{ t('general.switchCatalogAlert', { catalogName: userStore.activeCatalog?.CatalogName }) }}
        </div>
      </tx-dialog>
      <!-- <avatar id="modProfile" name="Andre Labaki" @click="showProfileMenu" /> -->
    </div>
    <!-- ARTICLE DETAILS -->
    <tx-drawer
      v-model="isArticleDetailsOpen" :width="enableNewStyleDetails ? '95%' : '1024px'" right
      @closed="closeArticleDetails"
    >
      <model-details
        v-if="enableNewStyleDetails && clickedArticle" :article="clickedArticle" :read-only="true" :request-id="selectedNotificationRequestId"
        @close="closeArticleDetails" @updated="onArticleUpdated" @change="onArticleClick"
      />
      <article-details
        v-else-if="clickedArticle" :article="clickedArticle"
        :read-only="true" @updated="onArticleUpdated" @change="onArticleClick"
      />
    </tx-drawer>
  </nav>
</template>

<script lang="ts" setup>
import type { LocationQueryRaw } from 'vue-router'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import useEventsBus from '../composables/eventBus'
import AppLogo from './svg/AppLogo.vue'
import ModelDetails from '@/modules/articleDetails/ModelDetails.vue'
import ArticleDetails from '@/modules/articleDetails/ArticleDetails.vue'
import TxDropdown from '@/shared/components/TxDropdown.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import type { INotificationItem } from '@/shared/components/NotificationsDropdown.vue'
import NotificationsDropdown from '@/shared/components/NotificationsDropdown.vue'
import ExportDialog from '@/modules/export/ExportDialog.vue'
import NotificationErrorDialog from '@/shared/components/NotificationErrorDialog.vue'
import type { Entity } from '@/services/db'
import utils from '@/services/utils'
import { useNotificationStore } from '@/store/notification'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import CatalogDetails from '@/models/catalogDetails'
import { privileges } from '@/models/constants'
import type MyArticle from '@/models/myArticle'
import TxDrawer from '@/shared/components/TxDrawer.vue'

interface IModule {
  id: string
  routeName: string
  name: string
  active: boolean
  icon: string
  label: string
}

interface IUserOption {
  label: string
  icon: string
  tooltip: string
  disabled: boolean
}

const emit = defineEmits<{
  (e: 'refresh'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const router = useRouter()
const route = useRoute()
const notificationStore = useNotificationStore()
const { emitter } = useEventsBus()
const switchWarningDialogVisible = ref(false)
const refExportDialog = ref<InstanceType<typeof ExportDialog>>()
const refNotificationErrorDialog = ref<InstanceType<typeof NotificationErrorDialog>>()
const showNotificationErrorDialog = ref(false)
const isArticleDetailsOpen = ref(false)
const clickedArticle = ref<MyArticle | null>(null)
const selectedNotificationRequestId = ref<number | undefined>(undefined)

const userOptions = ref<Record<string, IUserOption>>({
  switch: { label: userStore.currentUsername, icon: 'fa-light fa-users-rays', tooltip: t('general.switchUser'), disabled: (userStore.totalUsers <= 1) },
  logout: { label: t('general.logout'), icon: 'fa-light fa-arrow-right-from-bracket', tooltip: t('general.logout'), disabled: false },
})

function doGoto(routeName: string, query?: LocationQueryRaw) {
  console.log('going to route', routeName)
  router.push({ name: routeName, query }).catch((err) => { console.error(err) })
}

async function doRefreshData(notification?: INotificationItem) {
  emit('refresh')
  if (userStore.activeCatalog) {
    try {
      notificationStore.addNotification({ message: t('notifications.dataRefreshing'), type: 'Info' })
      const catalogDetails = new CatalogDetails(await appConfig.DB!.getCatalogDetails(userStore.activeCatalog.CatalogCode), userStore.userProfile.Roles)
      userStore.activeCatalog = catalogDetails
      await userStore.setMyAttributes()
      await userStore.setCurrentCustomerSegmentations()
      let isReloadAll = false
      const entitiesToLoad: Array<Entity> = []
      const isOrderModuleEnabled = userStore.activeCatalog.Config.DisableModules.join(',').toLowerCase().split(',').includes('order')
      switch (notification?.type) {
        case 'Articles':
        case 'importArticles':
        case 'assortArticles':
        case 'updateViaImport':
        case 'updateViaImportModel':
        case 'importByLicensee':
          entitiesToLoad.push('Articles')
          break
        case 'setPricesByLicenseeModelNumber':
          entitiesToLoad.push('Articles', 'Prices')
          break
        case 'setSizesByLicenseeModelNumber':
        case 'editSizes':
          entitiesToLoad.push('Articles', 'Sizes')
          break
        case 'editRequestBulk':
          entitiesToLoad.push('Requests')
          break
        default:
          isReloadAll = true

          // TODO: I am removing the sizes and allocations module for performance reasons, we must bring it back if the catalog has the Orders or Forecast module enabled!
          entitiesToLoad.push('Articles', 'Prices', 'DeliveryDates', 'Segmentations', 'Resources', 'MyFavoriteTags', 'SavedViews', 'ColorPallets', 'Sizes', 'Whiteboards')
          if (isOrderModuleEnabled) {
            entitiesToLoad.push('Locations')
            entitiesToLoad.push('CustomerSegmentations')
            await appConfig.DB?.getLinkedCustomers(userStore.activeCatalog, true)
          }
          break
      }

      if (isReloadAll) {
        if (catalogDetails.RequestsEnabled > 0) {
          entitiesToLoad.push('Requests')
        }
      }
      await userStore.doLoadData(entitiesToLoad, undefined, false)
      if (isReloadAll) {
        await userStore.setLinkedCatalogs(catalogDetails)
        for (const linkedCatalogCode in userStore.linkedCatalogDetails) {
          const linkedCatalogDetails = userStore.linkedCatalogDetails[linkedCatalogCode]
          if (linkedCatalogDetails && linkedCatalogDetails.Status > 0) {
            const entitiesToLoad: Entity[] = ['Articles', 'Prices', 'DeliveryDates', 'Segmentations', 'Resources', 'MyFavoriteTags']
            await userStore.doLoadData(entitiesToLoad, [linkedCatalogDetails.CatalogCode], false)
          }
        }
      }
      // Need to set full refresh true , as might be customer seg changes or article segmentations that is not depend article
      emitter('catalogDataUpdated', { fullReload: true, source: 'Refresh' })
      notificationStore.addNotification({ message: t('notifications.dataRefreshed'), type: 'Success' })
    }
    catch (error) {
      notificationStore.addNotification({ message: t('notifications.dataRefreshError'), type: 'Alert', details: utils.getErrorMessage(error), actions: ['ShowDetails', 'Support'] })
    }
  }
}

function doExport() {
  refExportDialog.value?.showDialog()
}

async function showErrorInformation(notificationId: string) {
  showNotificationErrorDialog.value = true
  refNotificationErrorDialog.value?.showDialog(refNotificationErrorDialog.value?.showDialog(notificationId))
}

function doUserAction(action: string, option: IUserOption) {
  if (option.disabled) { return }

  switch (action) {
    case 'logout':
      userStore.unsetToken()
      doGoto('Login', { redirect: router.currentRoute.value.fullPath })
      break
    case 'switch':
      if (userStore.totalUsers > 1) {
        doGoto('Login')
      }
      break
    default:
      break
  }
}

const getSwitchVisible = computed(() => route.name && !['CatalogsList', 'OpenCatalog'].includes(route.name.toString()))
const enabledModules = computed((): IModule[] => {
  const modules = [
    {
      id: 'browse',
      label: t('general.browse'),
      name: 'browse',
      icon: 'fa-light fa-book-open-cover',
      active: route.name === 'Catalog',
      routeName: 'Catalog',
    },
    {
      id: 'merch',
      label: t('merch.label'),
      name: 'merch',
      icon: 'fa-light fa-user-chart',
      active: route.name === 'Merch',
      routeName: 'Merch',
    },
    {
      id: 'whiteboards',
      label: t('general.whiteboard'),
      name: 'whiteboard',
      icon: 'fa-light fa-chalkboard',
      active: route.name === 'Whiteboards',
      routeName: 'Whiteboards',
    },
  ]
  if (!userStore.activeCatalog?.Config.DisableModules.join(',').toLowerCase().split(',').includes('order')) {
    modules.push({
      id: 'order',
      label: t('routes.orders'),
      name: 'orders',
      icon: 'fa-light fa-cart-shopping',
      active: route.name === 'Orders' || route.name === 'Orderlines',
      routeName: 'Orders',
    })
  }
  return getSwitchVisible.value ? modules : []
})

const getInitials = computed(() => {
  if (userStore.userProfile) {
    return userStore.userProfile.initials
  }
  return userStore.currentUsername && userStore.currentUsername !== '' ? utils.getInitials(userStore.currentUsername) : ''
})

const isAdmin = computed(() => {
  if (userStore.userProfile) {
    return userStore.currentUserType === '90' || userStore.userProfile.isValidPrivilege(privileges.admin.accessAdminModule)
  }
  return userStore.currentUserType === '90'
})
const enableNewStyleDetails = computed(() => userStore.activeCatalog?.Config.EnableNewStyleDetails || false)

function onConfirmSwitchCatalog() {
  switchWarningDialogVisible.value = false
  doGoto('CatalogsList')
  userStore.resetCatalogData()
}

watch(() => userStore.totalUsers, (val) => {
  userOptions.value.switch.disabled = val <= 1
})

function onArticleUpdated(key: string, value: any) {
  if (clickedArticle.value && clickedArticle.value.hasOwnProperty(key)) {
    clickedArticle.value[key] = value
  }
}
async function showRequestDetails(articleId: number, requestId?: number) {
  selectedNotificationRequestId.value = requestId
  try {
    const articles = await appConfig.DB!.getMyArticles(userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [articleId], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, false)
    if (articles.length) {
      clickedArticle.value = articles[0]
      isArticleDetailsOpen.value = true
    }
    else {
      const requestArticle = await appConfig.DB!.getRequestArticleById(userStore.activeCatalog!, articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
      if (requestArticle) {
        clickedArticle.value = requestArticle
        isArticleDetailsOpen.value = true
      }
    }
  }
  catch (error) {
    console.error(error)
    notificationStore.addNotification({ message: t('orderlines.alerts.unableToLoadArticleDetails'), type: 'Alert' })
  }
}
async function onArticleClick(article: MyArticle) {
  try {
    if (article.CatalogCode === userStore.activeCatalog!.CatalogCode || userStore.linkedCatalogDetails[article.CatalogCode]) {
      const articles = await appConfig.DB!.getMyArticles(userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [article.Id], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, false)
      clickedArticle.value = articles[0]
      isArticleDetailsOpen.value = true
    }
    else {
      clickedArticle.value = article
      isArticleDetailsOpen.value = true
    }
  }
  catch (error) {
    console.error(error)
    notificationStore.addNotification({ message: t('orderlines.alerts.unableToLoadArticleDetails'), type: 'Alert' })
  }
}

function closeArticleDetails() {
  isArticleDetailsOpen.value = false
  clickedArticle.value = null
}
</script>
