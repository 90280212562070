export class FetchInstance {
  token = ''
  baseURL = ''

  setToken(token: string) {
    this.token = token
  }

  setBaseURL(baseURL: string) {
    this.baseURL = baseURL
  }

  async get(url: string) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        'Accept-Encoding': 'gzip, deflate, br',
      },
    }

    try {
      const res = await fetch(`${this.baseURL}${url}`, options)

      if (!res.ok) {
        throw new Error(`HTTP Error: ${res.status} - ${res.statusText}`)
      }

      return await res.json()
    }
    catch (error) {
      console.error(`Error during GET request to ${url}:`, error)
      throw error
    }
  }

  async* getNDJson(url: string) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/x-ndjson',
      },
    }

    try {
      const response = await fetch(`${this.baseURL}${url}`, options)

      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }

      const reader = response.body?.getReader()
      if (!reader) {
        throw new Error('Readable stream not available in response body')
      }

      for await (const record of readableStream(reader)) {
        yield record
      }
      reader.releaseLock()
    }
    catch (error) {
      console.error(`Error during NDJSON request to ${url}:`, error)
      throw error
    }
  }
}

async function* readableStream(reader: ReadableStreamDefaultReader) {
  let runningText = ''
  const decoder = new TextDecoder('utf-8')

  while (true) {
    const { done, value } = await reader.read()
    if (done) { break }

    const text = decoder.decode(value, { stream: true })
    const objects = text.split('\n')

    for (const obj of objects) {
      runningText += obj

      try {
        const result = JSON.parse(runningText)
        yield result
        runningText = ''
      }
      catch {
        // If parsing fails, wait for more data
      }
    }
  }
}
