import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import { whiteboardConstants } from '@/models/constants'

export type ShapeType = 'triangle' | 'rectangle' | 'circle' | 'star'

interface IWbLineOptions extends fabric.IPolylineOptions {
  id?: string
  lock?: boolean
  preventUnlock?: boolean
  pathOffset?: { x: number, y: number }
}

export default class WbLine extends fabric.Polygon implements IWbObject {
  public id: string
  public type = whiteboardConstants.objectTypes.line
  public lock: boolean
  public preventUnlock: boolean
  public connectable: boolean
  public editableProps: Record<string, IWbObjectProp> = {
    border: { name: 'border', type: 'border' },
    lock: { name: 'Lock', type: 'lock' },
    addDiscussion: { name: 'add comment', type: 'addDiscussion' },
  }

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  constructor(points: Array<{ x: number, y: number }>, opt?: IWbLineOptions) {
    super(points, opt)
    this.stroke = opt?.stroke || 'black'
    this.strokeWidth = opt?.strokeWidth || 2
    this.id = opt?.id || guid()
    this.lock = opt?.lock || false
    if (opt?.pathOffset) {
      this.pathOffset = new fabric.Point(opt.pathOffset.x, opt.pathOffset.y)
    }
    this.strokeUniform = true
    this.connectable = true
    this.preventUnlock = opt?.preventUnlock || false
    this.objectCaching = false
    this.cornerStyle = 'circle'
    this.transparentCorners = false
    this.cornerColor = 'blue'
    this.controls = this.createLineControls()
    this.hasBorders = false
    this.perPixelTargetFind = true

    this.setLock(this.lock)

    this.stateProperties = this.stateProperties?.concat(['shapeType', 'lock', 'preventUnlock', 'points', 'pathOffset'])
  }

  createLineControls() {
    return {
      p0: new fabric.Control({
        positionHandler: polygonPositionHandler,
        actionHandler: anchorWrapper(1, actionHandler),
        actionName: 'modifyPolygon',
        pointIndex: 0,

      } as any),

      p1: new fabric.Control({
        positionHandler: polygonPositionHandler,
        actionHandler: anchorWrapper(0, actionHandler),
        actionName: 'modifyPolygon',
        pointIndex: 1,
      } as any),
    }
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'border':
        this.set('stroke', value.borderColor)
        this.set('strokeWidth', value.strokeWidth)
        break
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      case 'lockSkewingX':
        this.set('lockSkewingX', value)
        break
      case 'lockSkewingY':
        this.set('lockSkewingY', value)
        break
      case 'lockScalingX':
        this.set('lockScalingX', value)
        break
      case 'lockScalingY':
        this.set('lockScalingY', value)
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'border':
        result.borderColor = this.stroke
        result.strokeWidth = this.strokeWidth
        break
      case 'lock':
        result.lock = this.lock
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject(propertiesToInclude?: string[]) {
    const props = propertiesToInclude || []
    props.push('id', 'points', 'lock', 'preventUnlock', 'pathOffset')
    return super.toObject(props)
  }

  static fromObject(object: fabric.Object, callback?: Function) {
    return fabric.Object._fromObject(whiteboardConstants.objectTypes.line, object, callback, 'points') as WbLine
  }
}

function anchorWrapper(anchorIndex, fn) {
  return function (eventData, transform, x, y) {
    const fabricObject = transform.target
    const absolutePoint = fabric.util.transformPoint(
      new fabric.Point(fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x, fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
      fabricObject.calcTransformMatrix(),
    )
    const actionPerformed = fn(eventData, transform, x, y)
    fabricObject._setPositionDimensions({})
    const polygonBaseSize = fabricObject._getNonTransformedDimensions()
    const newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x
    const newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5)
    return actionPerformed
  }
}

function polygonPositionHandler(this: fabric.Control & { pointIndex: number }, dim, finalMatrix, fabricObject) {
  const x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x)
  const y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y)
  if (!fabricObject?.canvas?.viewportTransform) {
    return new fabric.Point(x, y)
  }
  return fabric.util.transformPoint(new fabric.Point(x, y), fabric.util.multiplyTransformMatrices(
    fabricObject.canvas.viewportTransform,
    fabricObject.calcTransformMatrix(),
  ))
}

/**
 * define a function that will define what the control does
 * this function will be called on every mouse move after a control has been
 * clicked and is being dragged.
 * The function receive as argument the mouse event, the current trasnform object
 * and the current position in canvas coordinate
 * transform.target is a reference to the current object being transformed,
 */
function actionHandler(eventData, transform, x, y) {
  const polygon = transform.target
  const currentControl = polygon.controls[polygon.__corner]
  const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center')
  const polygonBaseSize = polygon._getNonTransformedDimensions()
  const size = polygon._getTransformedDimensions(0, 0)
  const finalPointPosition = {
    x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
    y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y,
  }
  if (eventData.shiftKey) {
    const otherPoint = polygon.points[currentControl.pointIndex === 0 ? 1 : 0]
    // snap the two points to form a straight line or a 45 degree angle
    const dx = finalPointPosition.x - otherPoint.x
    const dy = finalPointPosition.y - otherPoint.y

    const angle = Math.atan2(dy, dx) * (180 / Math.PI)
    const angleAbs = Math.abs(angle)

    if (angleAbs < 22.5 || angleAbs > 157.5) {
      // Closer to horizontal line
      finalPointPosition.y = otherPoint.y
    }
    else if (angleAbs > 67.5 && angleAbs < 112.5) {
      // Closer to vertical line
      finalPointPosition.x = otherPoint.x
    }
    else {
      // Adjust to 45 degree line
      const delta = Math.max(Math.abs(dx), Math.abs(dy))
      const signX = dx >= 0 ? 1 : -1
      const signY = dy >= 0 ? 1 : -1
      finalPointPosition.x = otherPoint.x + signX * delta
      finalPointPosition.y = otherPoint.y + signY * delta
    }
  }
  polygon.points[currentControl.pointIndex] = finalPointPosition
  return true
}

const f: any = fabric
f.WbLine = WbLine
