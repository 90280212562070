<template class="container relative flex flex-col w-full h-full overflow-y-auto">
  <tx-dialog
    v-model="visible"
    :title="`${request?.IsCreateArticleRequest ? `Edit ${t(request?.RequestTypeTranslator)}` : getEditRequestTitle}: ${request?.IsCreateArticleRequest ? request?.Content.ArticleNumber : request?.SourceArticleId && articleIdArticleNumberMap && articleIdArticleNumberMap[request.SourceArticleId] ? articleIdArticleNumberMap[request?.SourceArticleId] : request?.SourceArticleNumber}`"
    width="60%"
    height="85%"
    show-ok-cancel
    :ok-state="v$.$invalid || loading ? 'disabled' : 'enabled'"
    @click="doCancel()" @ok="doSave()"
  >
    <div class="relative w-full h-full overflow-x-hidden">
      <loader v-if="loading" />
      <div v-else class="container flex flex-col w-full h-full">
        <!-- BODY -->
        <div class="px-10 mt-4 alerts">
          <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
        </div>
        <div class="h-full">
          <div class="grid w-full grid-cols-2 px-10 gap-x-6">
            <div
              v-for="(attribute) in visibleAttributes"
              :key="attribute.SystemName"
              class="mb-5 last-of-type:mb-4"
            >
              <attribute-editor
                v-model="form[attribute.SystemName]"
                :articles="sourceArticle ? [sourceArticle] : []"
                :attribute="attribute"
                :required="attribute.IsRequired"
                :show-checkbox="false"
                :form="form"
                :errors="v$[attribute.SystemName]?.$errors"
                :disabled="attribute.ReadOnly"
                @blur="v$[attribute.SystemName]?.$touch"
                @change="v$[attribute.SystemName]?.$touch"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { helpers, maxLength, required } from '@vuelidate/validators'
import { clone } from 'lodash-es'
import { useUserStore } from '@/store/userData'
import useErrorMessage from '@/shared/composables/errorMessage'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import utils from '@/services/utils'
import { editRequestKeys, requestConstants } from '@/models/constants'
import { AttributeType } from '@/models/catalogAttribute'
import Loader from '@/shared/components/Loader.vue'
import { updateRequest } from '@/api/t1/request'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import appConfig from '@/services/appConfig'
import type Article from '@/models/article'
import type RequestModel from '@/models/request'
import type { UpdateRequestModel } from '@/api/t1/model/requestModel'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'editRequest', item: RequestModel): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const form = reactive<Record<string, any>>({})

const visible = ref(false)
const loading = ref(false)
const request = ref<RequestModel>()
const sourceArticle = ref<Article>()
const updateAttribute = ref<IMyAttribute>()
const articleIdArticleNumberMap = ref<Record<number, string>>({})

const getEditRequestTitle = computed(() => {
  if (request.value?.IsCreateArticleRequest) {
    return ''
  }
  else {
    const key = updateAttribute.value?.SystemName
    let displayName = key
    if (key && userStore.myAttributes![key]) {
      displayName = userStore.myAttributes![key].DisplayName
      return `Edit ${displayName} Request`
    }
    return `Edit ${key} Request`
  }
})

const visibleAttributes = computed(() => {
  const visibleAttributes: IMyAttribute[] = []
  if (userStore.activeCatalog) {
    userStore.activeCatalog.RequestAttributeList.forEach((attribute) => {
      if (attribute.Status > 0 && attribute.AttributeTypeId !== AttributeType.Calc) {
        const requestAttributeField: IMyAttribute = {
          SystemName: attribute.AttributeSystemName,
          DisplayName: attribute.AttributeDisplayName,
          AttributeType: attribute.AttributeTypeId,
          AttributeSource: 'request',
          Creatable: true,
          Editable: true,
          Visible: true,
          IsRequired: true,
          IsStatic: false,
          IsSeasonless: false,
          IsModelLevel: false,
          IsRequest: true,
          Overridable: false,
          ValidationExpression: attribute.ValidationExpression,
          ValidationMessage: attribute.ValidationMessage,
          VettingList: userStore.myAttributes![attribute.AttributeSystemName].VettingList,
          AllowFiltering: userStore.myAttributes![attribute.AttributeSystemName].AllowFiltering,
          FilterLookup: userStore.myAttributes![attribute.AttributeSystemName].FilterLookup,
          Criteria: userStore.myAttributes![attribute.AttributeSystemName].Criteria,
          CriteriaVettingList: userStore.myAttributes![attribute.AttributeSystemName].CriteriaVettingList,
          parsedValidationExpression: userStore.myAttributes![attribute.AttributeSystemName].parsedValidationExpression,
          SortOrder: 0,
          prePopulate: false,
          ReadOnly: false,
          IsPersonal: userStore.myAttributes![attribute.AttributeSystemName].IsPersonal,
        }
        visibleAttributes.push(requestAttributeField)
      }
    })
    if (!request.value?.IsCreateArticleRequest) {
      if (!updateAttribute.value) { return [] }
      const formConfig = userStore.activeCatalog?.Config?.RequestForm
      if (formConfig && utils.isDefined(formConfig.modifyAttributes) && userStore.activeCatalog) {
        const modifyAttributesConfig = formConfig.modifyAttributes[updateAttribute.value!.SystemName]
          || formConfig.modifyAttributes.Default // Check for Default if specific config is missing

        if (modifyAttributesConfig) {
          for (let i = visibleAttributes.length - 1; i >= 0; i--) {
            if (!modifyAttributesConfig.includes(visibleAttributes[i].SystemName)) {
              visibleAttributes.splice(i, 1)
            }
          }
        }
      }
    }
    else {
      const requestSource = request.value?.RequestSource
      if (requestSource === requestConstants.requestSources.new || requestSource === requestConstants.requestSources.carryover) {
        if (userStore.activeCatalog.Config.RequestForm.carryoverModel?.requestAttributesForm
          && userStore.activeCatalog.Config.RequestForm.carryoverModel.requestAttributesForm.length > 0) {
          const configRequestAttributes = new Set(userStore.activeCatalog.Config.RequestForm.carryoverModel.requestAttributesForm.map(x => x.attribute))
          for (let i = visibleAttributes.length - 1; i >= 0; i--) {
            if (!configRequestAttributes.has(visibleAttributes[i].SystemName)) {
              visibleAttributes.splice(i, 1)
            }
          }
        }
      }
      else if (requestSource === requestConstants.requestSources.similarStyle) {
        if (userStore.activeCatalog.Config.RequestForm.copyModel?.requestAttributesForm
          && userStore.activeCatalog.Config.RequestForm.copyModel.requestAttributesForm.length > 0) {
          const configRequestAttributes = new Set(userStore.activeCatalog.Config.RequestForm.copyModel.requestAttributesForm.map(x => x.attribute))
          for (let i = visibleAttributes.length - 1; i >= 0; i--) {
            if (!configRequestAttributes.has(visibleAttributes[i].SystemName)) {
              visibleAttributes.splice(i, 1)
            }
          }
        }
      }
    }
  }
  return visibleAttributes
})

const rules = computed(() => {
  const result: Record<string, any> = {}
  visibleAttributes.value.forEach((attribute) => {
    result[attribute.SystemName] = {}
    if (attribute.IsRequired) {
      result[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
    }
    if (attribute.maxLength != null) {
      result[attribute.SystemName].maxLength = helpers.withMessage(t('validations.maxLength', { property: attribute.DisplayName, max: attribute.maxLength }), maxLength(attribute.maxLength))
    }
  })
  return result
})
const v$ = useVuelidate(rules, form)

async function showDialog(item: RequestModel, artIdArticleNumberMap?: Record<number, string> | undefined) {
  reset()
  loading.value = true

  request.value = item
  articleIdArticleNumberMap.value = artIdArticleNumberMap || {}
  const article = await appConfig.DB!.articles.get({ CatalogCode: item.CatalogCode, Id: item.SourceArticleId })
  if (utils.isDefined(article)) {
    sourceArticle.value = article
    if (item.IsCreateArticleRequest) {
      // create article request

    }
    else {
      const key = Object.keys(item.Content)[0]
      if (key && userStore.myAttributes![key]) {
        updateAttribute.value = userStore.myAttributes![key]
      }
      else if (key === editRequestKeys.prices) { // prices using the _retailPrice object
        updateAttribute.value = clone(userStore.myAttributes!._RetailPrice)
        updateAttribute.value.SystemName = editRequestKeys.prices
      }
    }

    if (utils.isDefined(item.RequestAttributes)) {
      visibleAttributes.value.forEach((attribute) => {
        if (!item.IsCreateArticleRequest && utils.isDefined(item.RequestAttributes![attribute.SystemName])) {
          form[attribute.SystemName] = item.RequestAttributes![attribute.SystemName][0]
        }
        else if (item.IsCreateArticleRequest) {
          if (utils.isDefined((item as any)._article)) {
            const art = (item as any)._article
            form[attribute.SystemName] = art[attribute.SystemName]
          }
          else {
            form[attribute.SystemName] = utils.getArticleAttributeDefaultValue(attribute)
          }
        }
        else {
          form[attribute.SystemName] = utils.getArticleAttributeDefaultValue(attribute)
        }
      })
    }
  }

  loading.value = false
}

function doCancel() {
  visible.value = false
  emit('cancel')
}

async function doSave() {
  errorMessage.value = ''
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
  }

  loading.value = true
  try {
    const payload: UpdateRequestModel = form

    if (userStore.activeCatalog) {
      await updateRequest(userStore.activeCatalog!.CatalogCode, request.value!.Id, payload)
        .then(async () => {
          await userStore.doLoadData(['Requests'])
          emit('editRequest', request.value!)
        })
        .catch ((error) => {
          console.error(error)
        })
    }
  }
  finally {
    loading.value = false
    visible.value = false
  }
}

function reset() {
  loading.value = false
  visible.value = true
  Object.keys(form).forEach((key) => {
    delete form[key] // Remove all form properties
  })
  v$.value.$reset()
  errorMessage.value = ''
}

defineExpose({
  showDialog,
})
</script>
