import { v4 as guid } from 'uuid'
import { fabric } from 'fabric'
import { getDiscussionDetails } from '@/api/t1/discussion'
import type UserProfile from '@/models/userProfile'
import { whiteboardConstants } from '@/models/constants'
import { useUserStore } from '@/store/userData'
import { generateUserColor } from '@/services/utils'

interface IWbDiscussionIconOptions extends fabric.IRectOptions {
  id?: string
  discussionId?: number
  title?: string
  resolved?: boolean
  comments?: IDiscussionComment[]
  preventUnlock?: boolean
}

export default class WbDiscussionIcon extends fabric.Rect implements IWbDiscussionIconOptions {
  public id: string
  public discussionId?: number
  public title?: string
  public resolved: boolean
  public comments: IDiscussionComment[]
  public type = whiteboardConstants.objectTypes.discussion
  public lock: boolean
  public connectable: boolean
  public editableProps: Record<string, IWbObjectProp> = {}
  public actions: Record<string, IWObjectActions> = {}
  public preventUnlock: boolean
  private uniqueUsers?: { userId: number, username: string, firstName: string, lastName: string }[] = []

  constructor(opt?: IWbDiscussionIconOptions) {
    super({ ...opt, height: 30 })
    this.id = opt?.id || guid()
    this.discussionId = opt?.discussionId
    this.title = opt?.title
    this.resolved = opt?.resolved || false
    this.comments = opt?.comments || []
    this.calcUniqueUsers()
    this.setWidth()
    this.fill = opt?.fill || 'red'
    this.opacity = opt?.opacity || 1
    this.hasControls = opt?.hasControls || false
    this.lock = false
    this.connectable = true
    this.preventUnlock = opt?.preventUnlock || false

    this.on('added', () => {
      const newZoom = this.canvas?.getZoom() || 1
      this.set('scaleX', 1 / newZoom)
      this.set('scaleY', 1 / newZoom)
    })

    this.on('zoom', () => {
      const newZoom = this.canvas?.getZoom() || 1
      this.set('scaleX', 1 / newZoom)
      this.set('scaleY', 1 / newZoom)
    })
  }

  calcUniqueUsers() {
    this.uniqueUsers = this.comments.filter((value, index, self) => self.findIndex(x => x.userId === value.userId) === index).map(x => ({ userId: x.userId, username: x.username, firstName: x.firstName, lastName: x.lastName }))
  }

  setWidth() {
    this.set('width', 30 /* Starting width */ + 20 /* Additional width per comment  */ * (this.uniqueUsers ? this.uniqueUsers.length - 1 : 0))
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'title':
        this.set('title', value.title)
        break
      case 'resolved':
        this.set('resolved', value.resolved)
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'title':
        result.title = this.title
        break
      case 'resolved':
        result.resolved = this.resolved
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  addComment(content: string, mentioned: IMentionItem[], userProfile: UserProfile) {
    this.comments.push({
      id: guid(),
      content,
      date: new Date(),
      userId: userProfile.Id,
      username: userProfile.UserName,
      firstName: userProfile.FirstName,
      lastName: userProfile.LastName,
      mentioned: mentioned.map(x => ({ value: x.value, type: x.type })),
    })
    this.calcUniqueUsers()
    this.setWidth()
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  migrateFromT1(catalogCode: number, discussionId: number) {
    return getDiscussionDetails(catalogCode, discussionId)
      .then((resp) => {
        if (resp.data) {
          this.title = resp.data.Title
          this.resolved = !!resp.data.Ended
          this.comments = []
          if (resp.data.Comments.length) {
            for (const comment of resp.data.Comments) {
              this.comments.push({
                id: comment.Id.toString(),
                content: comment.Comment,
                date: comment.CreatedDate,
                userId: comment.CreatedBy,
                username: comment.CreatedByUsername,
                firstName: comment.CreatedByFirstname,
                lastName: comment.CreatedByLastname,
                mentioned: [],
              })
            }
          }
          this.discussionId = undefined
          this.dirty = true
          this.canvas?.requestRenderAll()
          this.canvas?.fire('object:modified', { target: this })
        }
      })
      .catch(e => console.error(e))
  }

  setLockObject(locked: boolean) {
    this.set('lockMovementX', locked)
    this.set('lockMovementY', locked)
    this.set('lockRotation', locked)
    this.set('lockScalingFlip', locked)
    this.set('lockScalingX', locked)
    this.set('lockScalingY', locked)
    this.set('hasControls', !locked)
  }

  override toObject(propertiesToInclude?: string[]) {
    const props = propertiesToInclude || []
    props.push('id', 'title', 'resolved', 'comments', 'preventUnlock')
    return super.toObject(props)
  }

  static fromObject(opt: IWbDiscussionIconOptions, callback?: Function) {
    const object = new WbDiscussionIcon(opt)
    if (object.discussionId) {
      const userStore = useUserStore()
      if (userStore.activeCatalog) {
        object.migrateFromT1(userStore.activeCatalog.CatalogCode, object.discussionId)
          .then(() => console.log('migrated', object))
      }
    }
    return callback && callback(object)
    // return fabric.Object._fromObject(whiteboardConstants.objectTypes.discussion, object, callback) as WbDiscussionIcon
  }

  override _render(ctx: CanvasRenderingContext2D) {
    const startingX = -this.width! / 2 + 15
    ctx.fillStyle = 'blue'
    ctx.strokeStyle = 'blue'
    ctx.lineWidth = 10
    ctx.beginPath()
    ctx.arc(startingX, 0, 17, 0, Math.PI * 2)
    ctx.fill()
    if (this.uniqueUsers) {
      if (this.uniqueUsers.length > 1) {
        ctx.arc(startingX + (this.uniqueUsers.length - 1) * 20, 0, 17, 0, Math.PI * 2)
        ctx.fill()
        ctx.fillRect(startingX, -17, (this.uniqueUsers.length - 1) * 20, 34)
      }

      for (let i = this.uniqueUsers.length - 1; i >= 0; i--) {
        ctx.fillStyle = generateUserColor(this.uniqueUsers[i].username)
        ctx.strokeStyle = 'white'
        ctx.lineWidth = 8
        ctx.beginPath()
        ctx.arc(startingX + i * 20, 0, 11, 0, Math.PI * 2)
        ctx.stroke()
        ctx.fill()
        ctx.font = '13px Arial'
        ctx.fillStyle = 'black'
        ctx.textAlign = 'center'
        const measure = ctx.measureText(this.uniqueUsers[i].firstName[0])
        const height = measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent
        ctx.fillText(this.uniqueUsers[i].firstName[0], startingX + i * 20, height / 2)
      }
    }
  }
}

const f: any = fabric
f.WbDiscussionIcon = WbDiscussionIcon
